<template>
  <div
    class="login-container"
    :style="{ backgroundImage: 'url('+picture+')', backgroundPosition: 'center 0', backgroundRepeat: 'no-repeat', backgroundColor: '#1d1e1e', backgroundSize: '100% 100%'}"
  >
    <div class="header-box" />
    <!-- 登录界面 -->
    <div class="sign-in">
      <el-form ref="userInfo" :rules="rules" :model="userInfo">
        <el-form-item prop="phone">
          <el-input v-model="userInfo.phone" class="sign-input" type="text" placeholder="手机号码">
            <svg-icon
              slot="prefix"
              style="margin-top: 15px; "
              name="phone"
              color="#fff"
              width="18"
              height="18"
            />
          </el-input>
        </el-form-item>
        <el-form-item prop="captcha">
          <el-input v-model="userInfo.captcha" class="sign-input top" type="password" placeholder="验证码">
            <svg-icon
              slot="prefix"
              style="margin-top: 15px; "
              name="idcode"
              color="#fff"
              width="18"
              height="18"
            />
            <el-button slot="suffix" class="forget-pwd" type="text" :disabled="disabled" @click="getIdCode">{{
              btnText
            }}
            </el-button>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="sign-button" @click="login('userInfo')">
            <span class="btn-text">登录</span>
          </el-button>
        </el-form-item>
        <!-- <div class="register-notice">
          我没有账号，
          <span class="register" @click="registerAccount">注册新账号</span>
        </div> -->
      </el-form>
    </div>
  </div>
</template>

<script>
import '@/icons/phone'
import '@/icons/password'
import '@/icons/idcode'
import { getLoginCaptcha } from '@/api/login'
import { getUserRole, removeToken } from '@/utils/auth'
// import {sendLogin} from "@/api/login";

export default {
  data() {
    return {
      picture: require('@/assets/bottom.png'),
      userInfo: {
        phone: '',
        captcha: ''
      },

      // 获取验证码设置
      disabled: false,
      interval: null,
      totalCount: 0,

      rules: {
        phone: [{ required: true, message: '请输入电话号码', trigger: 'blur' }],
        captcha: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      }
    }
  },
  computed: {
    btnText() {
      // console.log('totalcount', typeof(this.totalCount))
      return this.totalCount !== 0 ? `${this.totalCount}秒再次获取` : '获取验证码'
    }
  },
  methods: {
    // login
    login(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const loginData = {
            account: this.userInfo.phone,
            captcha: this.userInfo.captcha
          }
          const type = 'captcha'
          this.$store.dispatch('user/login', { loginData, type }).then(() => {
            const role = getUserRole()
            console.log('role', role)
            if (role === 'ROLE_EXPERT') {
              this.$message.success('登录成功！')
              this.$router.push('/teacher/judgelist')
            } else {
              this.$message.warning('您的身份不匹配')
              removeToken()
              this.$router.push('/')
            }
          })
        } else {
          this.$message.warning('信息填写有误')
        }
      })
      console.log('login in')
    },
    // 获取验证码
    getIdCode() {
      console.log('get Id Code')
      console.log(this.userInfo)
      this.disabled = true
      this.totalCount = 60
      getLoginCaptcha(this.userInfo.phone).then(() => {
        this.$message.success('发送成功')
      })
      // 倒计时重发验证码
      this.interval = setInterval(() => {
        this.totalCount--
        if (this.totalCount === 0) {
          clearInterval(this.interval)
          this.disabled = false
        }
      }, 1000)
    },
    // 重置账号
    resetAccount() {
      console.log('reset account')
      this.$router.push({ name: 'ResetPassword' })
    },
    // 进行账号注册
    registerAccount() {
      console.log('register acctoun')
      this.$router.push('register')
    }
  }
}
</script>

<style scoped>
.header-box {
  height: 60px;
}

.login-container {
  height: calc(100vh - 60px);
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  /* border: 1px solid white; */
  /* -webkit-box-align: center; */
}

.sign-in {
  position: relative;
  width: 300px;
  /* height: 200px; */
  margin: auto;
  /* -webkit-box-align: center; */
  color: white;
  /* border: 1px solid white; */
}

.top {
  margin-top: 20px;
}

.sign-input {
  /* position: relative; */
  /* height: 60px; */
  font-size: 16px;
}

.sign-input /deep/ .el-input__inner {
  border: none;
  height: 50px;
  border-bottom: 1px solid #fff;
  padding-left: 40px;
  border-radius: 0px;
  background-color: transparent;
  color: white;
}

.sign-button {
  width: 100%;
  /* font-weight: 800; */
  height: 50px;
  margin-top: 20px;
}

.btn-text {
  font-weight: 800;
}

.forget-pwd {
  font-size: 13px;
  margin-top: 5px;
  /* color: white; */
}

.forget-pwd:hover {
  cursor: pointer;
}

.register-notice {
  margin-top: 23px;
  font-size: 13px;
  text-align: center;
}

.register {
  border-bottom: 1px solid #fff;
}

.register:hover {
  cursor: pointer;
}

.el-button--text {
  color: white;
}
</style>
